export const notFoundData = {
    title: '404 - Page Not Found',
    breadcrumbs: [
        { title: 'Home', pageSlug: '/' },
        {
          title: '404 - Page Not Found',
          pageSlug: '404',
          isActive: true
        }
    ],
    hasImage: false
};
