import React from 'react';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import Layout from '~layouts/layout';
import Helmet from 'react-helmet';
import useSiteMetadata from '~3-data-components/useSiteMetaData';
import { notFoundData } from '~data/404Data';

const PageNotFound404 = () => {
    const { site_title, site_description, site_url, default_share_image, twitter_site } = useSiteMetadata();

    return (
        <Layout>
            <RenderedContext.Provider value={notFoundData}>
                <Header />
            </RenderedContext.Provider>

            <Helmet
                title={`404 - Page Not Found | ${site_title.value}`}
            >
                <meta name="description" content={site_description.value} />
                <meta property="og:type" content='website' />
                <meta property="og:site_name" content={site_title.value} />
                <meta property="og:url" content={`${site_url.value}/404`} />
                <meta property="og:title" content={site_title.value} />
                <meta property="og:description" content={site_description.value} />
                <meta property="og:image" content={default_share_image.value[0].url} />
                <meta name="twitter:card" value='summary' />
                <meta name="twitter:site" content={twitter_site.value} />
                <meta name="twitter:creator" content={twitter_site.value} />
                <meta name="twitter:url" content={`${site_url.value}/404`} />
                <meta name="twitter:title" content={site_title.value} />
                <meta name="twitter:description" content={site_description.value} />
                <meta name="twitter:image" content={default_share_image.value[0].url} />
                <link rel="canonical" href={`${site_url.value}/404`} />
            </Helmet>

            <section>
                <div className="container">
                    <div className="page-section">
                        <div className="left-col page-not-found-content">
                            <h4>We looked everywhere but couldn&#39;t find that page ...</h4>
                            <p>The page you’ve requested does not exist, has been moved or has been taken off the site. We apologise for the inconvenience.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default PageNotFound404;
